<template>
	<!-- Mobile -->
	<div v-if="isMobile" class="pb-3">
		<div
			class="top-0 bg-stan-bg z-20 w-full flex flex-row flex-nowrap px-4 items-center"
			:class="showTabs || showBack ? 'justify-between' : 'justify-end'"
		>
			<AppTabs
				v-if="showTabs"
				id="community-internal-tabs"
				:items="tabs"
				:active-tab="currentRouteName"
				@tabSelected="onTabSelected"
				class="w-[70%] text-nowrap overflow-x-scroll"
			/>
			<div v-else-if="showBack">
				<AppButton link @click="backClicked"> <AppIcon src="arrow-left" /> <span class="text-stan-text-primary">Back</span></AppButton>
			</div>

			<AppIcon src="menu" @click="toggleMobileMenu" class="cursor-pointer" :class="{ 'mt-5': !showTabs }" />
		</div>
		<h1 class="title">{{ communityPublicStore.name }}</h1>
	</div>
	<!-- Desktop -->
	<div v-else class="bg-stan-gray-mid-light z-20 grid lg:grid-cols-3 grid-cols-2 gap-6">
		<div class="justify-center col-span-3 flex flex-col">
			<h1 class="title">{{ communityPublicStore.name }}</h1>
			<AppTabs
				v-if="showTabs"
				id="community-internal-tabs"
				:items="tabs"
				:active-tab="currentRouteName"
				@tabSelected="onTabSelected"
				class="w-[70%] text-nowrap overflow-x-scroll"
			/>
			<div v-else-if="showBack" class="py-4">
				<AppButton link @click="backClicked"> <AppIcon src="arrow-left" /> <span class="text-stan-text-primary">Back</span></AppButton>
			</div>
		</div>
		<!-- On mobile show menu on desktop show search bar -->
	</div>
</template>

<script>
	import mobileCheckMixin from '@/layouts/MobileCheckMixin'
	import { useCommunityPublicStore } from '@/stores/public'
	export default {
		name: 'NavigationBar',
		mixins: [mobileCheckMixin],
		props: {
			tabs: {
				type: Array,
				require: true,
				default: () => [],
			},
		},
		computed: {
			communityPublicStore() {
				return useCommunityPublicStore()
			},
			currentRouteName() {
				return this.$route.name
			},
			showTabs() {
				if (['members', 'search', 'resourceDigitalDownload', 'resourceFolder', 'child'].includes(this.$route.name)) {
					return false
				}
				return true
			},
			showBack() {
				if (['members', 'search', 'resourceDigitalDownload', 'resourceFolder', 'child'].includes(this.$route.name)) {
					return true
				}
				return false
			},
			isSearchPage() {
				if (['search'].includes(this.$route.name)) {
					return true
				}
				return false
			},
		},
		methods: {
			toggleMobileMenu() {
				this.$emit('toggleMobileMenu')
			},
			onTabSelected(selected) {
				this.$router.push({ name: selected })
			},
			backClicked() {
				this.$router.back()
			},
		},
	}
</script>
<style lang="scss" scoped>
	.title {
		@apply -tracking-30 mt-10;
		text-transform: capitalize;
		text-wrap: wrap;
		word-break: break-word;

		font-family: 'Plus Jakarta Sans';
		font-size: 32px;
		font-style: normal;
		font-weight: 800;
		line-height: 100%;
		max-width: 100%;
	}
	.header-container {
		@apply h-16 md:h-60 w-screen relative;

		img {
			@apply h-16 md:h-60 w-full object-cover justify-center;
		}
	}
</style>
