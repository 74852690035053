<template>
	<AppModalFrame ref="modal" extraLarge :padding="false">
		<div class="container">
			<div class="post-item-wrapper">
				<Post
					v-if="messageId"
					:messageId="messageId"
					@reaction-updated="reactionUpdated = !reactionUpdated"
					:key="reactionUpdated"
					class="pb-6"
				/>
			</div>
			<FullCommentSection :messageId="messageId" />
		</div>
	</AppModalFrame>
</template>
<script>
	import { useCommunityStore } from '@/stores/communities'
	export default {
		name: 'ModalPost',
		data() {
			return {
				reactionUpdated: false,
			}
		},
		props: {
			messageId: {
				type: Number,
				required: true,
			},
		},
		computed: {
			communityStore() {
				return useCommunityStore()
			},
			sizeClass() {
				if (this.extraLarge) return 'modal-xl'
				if (this.large) return 'modal-lg'
				return ''
			},
			modal() {
				return this.$refs.modal
			},
		},
		mounted() {
			if (this.messageId) this.show()
		},
		methods: {
			show() {
				this.modal.show()
			},
			hide() {
				this.modal.hide()
			},
		},
	}
</script>
<style lang="scss" scoped>
	.container {
		height: 80vh;
		@apply md:flex md:flex-row overflow-y-scroll md:overflow-y-visible rounded-md;
	}
	.post-item-wrapper {
		@apply w-full md:w-7/12 grow bg-stan-foreground overflow-y-scroll overflow-x-hidden flex;
		.post-container {
			@apply p-7;
		}
	}
	.full-comment-section-container {
		@apply w-full md:w-5/12;
	}
</style>
