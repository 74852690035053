<template>
	<div class="bg-stan-foreground rounded-2xl p-4 relative">
		<div class="pb-6 pt-3 flex flex-row justify-between">
			<h3 class="text-bold flex items-center gap-1">Follow Along</h3>
		</div>
		<div
			class="shadow-stan-box-shadow relative overflow-visible rounded-xl bg-stan-foreground mb-4 hover:cursor-pointer p-4 px-3 gap-3;"
			@click="connectApplePodcast"
		>
			<div class="flex flex-row gap-2 w-full items-center rounded-xl text-stan-text-primary text-sm">
				<img
					src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Podcasts_%28iOS%29.svg/300px-Podcasts_%28iOS%29.svg.png?20211002125540"
				/>
				<div class="overflow-hidden">
					<div class="helpful-webinar-title para-2 text-bold">Apple Podcast</div>
				</div>
			</div>
		</div>
		<div
			class="shadow-stan-box-shadow relative overflow-visible rounded-xl bg-stan-foreground mb-4 hover:cursor-pointer p-4 px-3 gap-3;"
			@click="connectSpotify"
		>
			<div class="flex flex-row gap-2 w-full items-center rounded-xl text-stan-text-primary text-sm">
				<img src="https://storage.googleapis.com/pr-newsroom-wp/1/2023/05/Spotify_Primary_Logo_RGB_Green.png" />
				<div class="overflow-hidden">
					<div class="helpful-webinar-title para-2 text-bold">Spotify</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup>
	import { useCommunityStore } from '@/stores/communities'
	import { ref, computed, getCurrentInstance } from 'vue'
	import { useRouter } from 'vue-router'
	const communityStore = useCommunityStore()
	const router = useRouter()

	const connectApplePodcast = () => {
		window.open(`${applePodCastURL.value}v1/rss/${communityStore.currentCommunity.slug}/${communityStore.obfuscatedId}`, '_blank')
	}
	const connectSpotify = () => {
		router.push({ name: 'linkWithSpotify', query: { spotifyShowUri: spotifyShowID.value } })
	}
	const app = getCurrentInstance().appContext

	const applePodCastURL = computed(() => app.config.globalProperties.$configSettings.communityApiUrl.replace(/^https?:\/\//, 'podcast://'))
	const spotifyShowID = computed(() => communityStore.currentCommunity.data.spotify_uri)
</script>
<style lang="scss" scoped>
	img {
		height: 2.5rem;
		width: 2.5rem;
		border-radius: 9999px;
	}
</style>
