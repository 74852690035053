<template>
	<div class="dropdown relative rounded-full" ref="dropdown">
		<div @click="toggleMenu" class="cursor-pointer rounded-full bg-stan-foreground p-3 relative">
			<div
				v-if="Object.values(unreadNotification).length"
				class="h-2 w-2 right-2 bg-community-primary z-10 absolute rounded-full flex items-center justify-center text-[8px] text-stan-gray-primary"
			></div>
			<AppIcon src="bell" />
		</div>
		<div v-if="isMobile && isMenuOpen" class="drawer bg-stan-bg" :class="{ open: isMenuOpen }">
			<div class="drawer-content">
				<div class="text-stan-text-primary flex justify-between items-center text-bold p-2">
					Notifications
					<AppButton link color="light" icon="check" @click="markNotificationsAsCleared"> Clear </AppButton>
				</div>
				<div v-if="Object.keys(notifications).length === 0" class="p-4 text-center text-gray-500">No new notifications!</div>
				<div v-else>
					<div
						v-for="notification in unreadNotification"
						@click="clickNotification(notification)"
						:key="notification.message_notification_id"
						class="flex flex-row w-full justify-center rounded-xl text-stan-text-primary text-sm gap-3 items-center hover:bg-stan-foreground p-3 pr-20"
					>
						<AppUserImage
							:name="members[notification?.data.sender_id.toString()].data.full_name"
							:src="members[notification?.data.sender_id.toString()].data.profile_image"
							:color="members[notification?.data.sender_id.toString()].data.color"
							size="md"
						/>
						<div class="flex flex-col gap-2 w-full">
							<div class="notification-text para-2 break-words whitespace-normal">
								{{ notificationText(notification) }}
							</div>
							<span class="time para-3">{{ localSentTimeText(notification.timestamp) }}</span>
						</div>
						<div class="w-fill flex flex-row items-end justify-end">
							<div class="h-2 w-2 rounded-full bg-community-primary"></div>
						</div>
					</div>
					<div
						v-for="notification in readNotifications"
						@click="clickNotification(notification)"
						:key="notification.message_notification_id"
						class="flex flex-row w-full justify-center rounded-xl text-stan-text-primary text-sm gap-3 items-center hover:bg-stan-foreground p-3 pr-20"
					>
						<AppUserImage
							:name="members[notification?.data.sender_id.toString()].data.full_name"
							:src="members[notification?.data.sender_id.toString()].data.profile_image"
							:color="members[notification?.data.sender_id.toString()].data.color"
							size="md"
						/>
						<div class="flex flex-col gap-2 w-full">
							<div class="notification-text para-2">
								{{ notificationText(notification) }}
							</div>
							<span class="time para-3">{{ localSentTimeText(notification.timestamp) }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="!isMobile && isMenuOpen" class="dropdown-menu rounded-xl">
			<div class="rounded-xl bg-stan-foreground mb-4 px-2 gap-3 dropdown-container">
				<div class="flex justify-between items-center text-stan-text-primary text-bold p-2 pt-4 sticky bg-stan-foreground top-0">
					Notifications
					<div>
						<AppButton link color="light" icon="check" @click="markNotificationsAsCleared"> Clear </AppButton>
					</div>
				</div>
				<div v-if="Object.keys(notifications).length === 0" class="p-4 text-center text-gray-500">No new notifications!</div>
				<div v-else class="overflow-scroll">
					<div
						v-for="notification in unreadNotification"
						@click="clickNotification(notification)"
						:key="notification.message_notification_id"
						class="flex flex-row w-full justify-center rounded-xl text-stan-text-primary text-sm gap-3 items-center hover:bg-stan-foreground p-3 pr-20"
					>
						<AppUserImage
							:name="members[notification?.data.sender_id.toString()].data.full_name"
							:src="members[notification?.data.sender_id.toString()].data.profile_image"
							:color="members[notification?.data.sender_id.toString()].data.color"
							size="md"
						/>
						<div class="flex flex-col gap-2 w-full">
							<div class="notification-text para-2">
								{{ notificationText(notification) }}
							</div>
							<span class="time para-3">{{ localSentTimeText(notification.timestamp) }}</span>
						</div>
						<div class="w-fill flex flex-row items-center justify-center">
							<div class="h-2 w-2 rounded-full bg-community-primary"></div>
						</div>
					</div>
					<div
						v-for="notification in readNotifications"
						@click="clickNotification(notification)"
						:key="notification.message_notification_id"
						class="flex flex-row w-full justify-center rounded-xl text-stan-text-primary text-sm gap-3 items-center hover:bg-stan-foreground p-3 pr-20"
					>
						<AppUserImage
							:name="members[notification?.data.sender_id.toString()].data.full_name"
							:src="members[notification?.data.sender_id.toString()].data.profile_image"
							:color="members[notification?.data.sender_id.toString()].data.color"
							size="md"
						/>
						<div class="flex flex-col gap-2 w-full">
							<div class="notification-text para-2">
								{{ notificationText(notification) }}
							</div>
							<span class="time para-3">{{ localSentTimeText(notification.timestamp) }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, computed, onMounted, onBeforeUnmount, inject } from 'vue'
	import { useCommunityStore } from '@/stores/communities'
	import commonHelpers from '@/global_helper/helpers'
	import { DateTime } from 'luxon'
	import constants from '@/global_helper/constants'
	import helpers from '@/global_helper/helpers'

	const stanAnalytics = inject('stanAnalytics')

	const communityStore = computed(() => useCommunityStore())
	const isMobile = computed(() => helpers.isMobile())
	const notifications = computed(() => communityStore.value.notifications || {})
	const sortedNotifications = computed(() => Object.values(notifications.value).sort((a, b) => b.timestamp - a.timestamp))
	const readNotifications = computed(() =>
		Object.values(notifications.value)
			.filter(notification => notification.status === constants.NOTIFICATION_STATUS.OPENED)
			.sort((a, b) => b.timestamp - a.timestamp)
	)

	const unreadNotification = computed(() =>
		Object.values(notifications.value)
			.filter(notification => notification.status !== constants.NOTIFICATION_STATUS.OPENED)
			.sort((a, b) => b.timestamp - a.timestamp)
	)
	const members = computed(() => communityStore.value.members || {})

	const isMenuOpen = ref(false)

	const localSentTimeText = timestamp => {
		const date = DateTime.fromSeconds(timestamp)
		return commonHelpers.localSentTimeText(date)
	}

	const markNotificationsAsCleared = async () => {
		try {
			await communityStore.value.clearAllNotificationStatus()
		} catch (error) {
			console.error('Error marking notifications as cleared:', error)
		}
	}
	const markSingleNotificationAsOpened = async messageNotificationId => {
		try {
			await communityStore.value.updateNotificationStatus(messageNotificationId, constants.NOTIFICATION_STATUS.OPENED)
		} catch (error) {
			console.error('Error marking notification as cleared:', error)
		}
	}

	const isRootMessage = notification => {
		return notification.message_id == notification.data.root_message_id
	}

	const notificationText = notification => {
		const notificationSender = members.value[notification?.data.sender_id.toString()]
		let senderName = notificationSender.data.full_name
		if (notificationSender.status != constants.MEMBER_STATUS.ACTIVE) {
			senderName = senderName + ' (Inactive)'
		}
		const content = notification.content ? ` "${notification.content.slice(0, 30)}..."` : ''
		if (notification.type === 'reply') {
			return `${senderName} replied to you ${content}`
		} else if (notification.type === 'creator_post') {
			return `${senderName} made a new post ${content}`
		} else if (notification.type === 'mention') {
			return `${senderName} mentioned you in a ${isRootMessage(notification) ? 'post' : 'reply'} ${content}`
		}
		return `${notification.sender} sent a notification`
	}

	const toggleMenu = () => {
		isMenuOpen.value = !isMenuOpen.value
	}

	const closeMenu = () => {
		isMenuOpen.value = false
	}

	const handleClickOutside = event => {
		if (event.target.closest('.dropdown') === null) {
			closeMenu()
		}
	}

	onMounted(() => {
		document.addEventListener('click', handleClickOutside)
	})

	onBeforeUnmount(() => {
		document.removeEventListener('click', handleClickOutside)
	})
	const emit = defineEmits(['viewPost'])
	const clickNotification = async notification => {
		stanAnalytics('community-notification-click')
		try {
			stanAnalytics('community-notification-view-post')
			if (notification.type === 'reply') {
				emit('viewPost', notification.data.root_message_id, notification.message_id)
			} else if (notification.type === 'mention' && !isRootMessage(notification)) {
				emit('viewPost', notification.data.root_message_id, notification.message_id)
			} else {
				emit('viewPost', notification.data.root_message_id)
			}
			toggleMenu()
			await markSingleNotificationAsOpened(notification.message_notification_id)
		} catch (error) {
			console.error('Error opening notification', error)
		}
	}
</script>

<style scoped>
	.dropdown {
		@apply bg-stan-foreground;
		position: relative;
		display: inline-block;
	}

	.dropdown-menu {
		cursor: pointer;
		position: absolute;
		top: 100%;
		transform: translateX(-100%);
		z-index: 1000;

		min-width: 400px;
	}

	.drawer {
		position: fixed;
		top: 0;
		right: 0;
		width: 300px; /* Adjust as needed */
		height: 100%;
		box-shadow: var(--stan-box-shadow);
		transform: translateX(100%);
		transition: transform 0.3s ease;
		z-index: 2000;
	}

	.drawer-content {
		height: 100%;
		overflow-y: auto;
		padding: 1rem;
	}

	.drawer.open {
		transform: translateX(0);
	}

	.dropdown-container {
		max-height: 400px;
		overflow-y: scroll;
		z-index: 1000;
		overscroll-behavior: contain;
		position: relative;
	}

	.notification-item {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 1rem;
		border-radius: 0.5rem;
		gap: 0.75rem;
		background: white;
		color: var(--stan-text-primary);
	}

	.notification-item:hover {
		background-color: var(--stan-gray-light);
	}
</style>
