<template>
	<div class="post-container" :class="{ inline: inline }">
		<div class="flex flex-col gap-y-5 grow overflow-y-scroll overflow-x-hidden" :class="{ 'max-h-full h-auto': showGallery }">
			<div class="flex gap-x-2">
				<AppUserImage
					:name="sender?.data?.full_name"
					:src="sender?.data?.profile_image"
					:color="sender?.data.color"
					size="md"
					v-if="!showGallery"
				/>
				<div class="flex flex-row justify-between items-start grow">
					<AppButton v-show="showGallery" link @click="showGallery = false" icon="pointer-left">Back</AppButton>
					<div class="flex flex-row gap-2 items-center">
						<SenderNameAndTime :sender="sender" :time="time" v-if="!showGallery" />
						<div v-if="message.tag_id" class="para-3 -tracking-40 sm:pt-1 leading-none">
							in <span class="text-bold"> {{ tags[message.tag_id].name }}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="post-wrapper" :class="{ 'max-h-full h-auto flex-row': showGallery }">
				<MediaGallery class="media" v-show="showGallery" :assets="mediaDigitalAssets" ref="mediaGallery" />

				<div class="content para-2" v-html="processedContent" v-if="processedContent" v-show="!showGallery"></div>

				<div class="media para-2" v-if="assets?.length" v-show="!showGallery">
					<PostAssets :assets="assets" :inline="inline" @assetClicked="assetClicked" />
				</div>
				<PostReactionsList class="mt-2" :class="{ 'pb-6': !inline }" :reactions="reactions" v-show="!showGallery" :only-likes="false" />
			</div>
		</div>
	</div>
</template>
<script setup>
	import { usePost } from './PostComposable'
	import { DateTime } from 'luxon'
	import { ref, computed, nextTick, defineComponent } from 'vue'
	import { digitalAssetType } from '@/components/shared/utils'
	import EditPostDropdownV2 from '@/components/post/EditPostDropdownV2.vue'

	const mediaAssetTypes = ['image', 'video']
	defineComponent({ components: { EditPostDropdownV2 } })
	const props = defineProps({
		messageId: {
			type: Number,
			required: true,
		},
		inline: {
			type: Boolean,
			default: false,
		},
		isMobile: {
			type: Boolean,
			default: false,
		},
	})
	const { communityStore, message, reactions } = usePost(props.messageId)
	const emit = defineEmits(['viewPost', 'gallery-open', 'reaction-updated'])

	const mediaGallery = ref(null)
	const showGallery = ref(false)

	const time = computed(() => DateTime.fromSeconds(message?.value.timestamp ?? 0))
	const assets = computed(() => message?.value.data?.digital_assets || [])
	const content = computed(() => message?.value.content ?? '')

	const selfId = computed(() => communityStore.self.community_member_id)
	const members = computed(() => communityStore.members)
	const tags = computed(() => communityStore.tags)
	const sender = computed(() => communityStore.members[message?.value.sender_id])

	const processedContent = computed(() => {
		if (!content.value) return ''
		let tempContent = content.value

		const regex = /<([a-zA-Z]+)([^>]*?)data-id="(\d+)"(.*?)>(.*?)<\/\1>/g

		tempContent = tempContent.replace(regex, (match, tag, before, id, after, innerHTML) => {
			const fullName = members.value[id]?.data?.full_name || 'Unknown Member'
			const mentionClass = selfId.value === parseInt(id) ? 'current-user-mention' : 'mention'

			// Update the class attribute in the `before` string
			const updatedBefore = before.replace(/class="([^"]*)"/, (classMatch, classNames) => `class="${mentionClass}"`)

			// If no class attribute exists, add it
			const finalBefore = updatedBefore.includes('class=') ? updatedBefore : `${updatedBefore} class="${mentionClass}"`

			return `<${tag}${finalBefore}${after}>@${fullName}</${tag}>`
		})

		return tempContent
	})

	// const digitalAssetType = digitalAssetType
	// const digitalAssetTypeName = digitalAssetTypeName

	const mediaDigitalAssets = computed(() => assets.value?.filter(digitalAsset => mediaAssetTypes.includes(digitalAssetType(digitalAsset))) || [])

	const assetClicked = asset => {
		if (props.inline) emit('viewPost')
		else {
			const index = mediaDigitalAssets.value.findIndex(mediaAsset => mediaAsset.url === asset.url)
			if (index > -1) {
				mediaGallery.value.showAtIndex(index)
				nextTick(() => {
					showGallery.value = true
					emit('gallery-open')
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.post-container {
		@apply flex flex-row gap-3 bg-stan-foreground rounded-xl w-full h-full;

		.post-wrapper {
			@apply flex flex-col gap-2 bg-stan-foreground w-full grow;
			.media {
				@apply w-full  my-2 max-h-full;
				:deep(.post-mixed-media-attachement-container) {
					.image {
						aspect-ratio: 1.28;
					}
				}
				:deep(#artplayer-app) {
					@apply h-full;
				}
			}
		}
		&.inline {
			.media {
				@apply overflow-hidden;
			}
		}
	}
</style>
