import AuthContainer from '@/layouts/AuthContainer.vue'
import NoAuthContainer from '@/layouts/NoAuthContainer.vue'
import MainContainer from '@/layouts/MainContainer.vue'
import MinimalContainer from '@/layouts/MinimalContainer.vue'
import PrepareStores from './PrepareStores'
import EmptyContainer from '@/layouts/EmptyContainer.vue'
import { configSettings } from '@/plugins/configSettings'
import { useAuthStore } from '@/stores/auth'

const communityLoginRoutes = {
	path: '/:communitySlug',
	name: 'communityAuth',
	component: AuthContainer,
	children: [
		{
			path: '',
			name: 'login',
			component: () => import('./Login.vue').then(m => m.default || m),
		},
		{
			path: 'magic-link',
			name: 'magicLink',
			component: () => import('./MagicLink.vue').then(m => m.default || m),
		},
		{
			path: 'reset-password',
			name: 'resetPassword',
			component: () => import('./ResetPassword.vue').then(m => m.default || m),
		},
		{
			path: 'set-password',
			name: 'setPassword',
			component: () => import('./ResetPassword.vue').then(m => m.default || m),
		},
		{
			path: 'forgot-password',
			name: 'forgotPassword',
			component: () => import('./ForgotPassword.vue').then(m => m.default || m),
		},
	],
}

const communityRoutes = {
	path: '/:communitySlug',
	name: 'community',
	redirect: '/:communitySlug',
	component: MainContainer,
	beforeEnter: PrepareStores,
	children: [
		{
			path: 'home',
			name: 'home',
			meta: { middlewares: ['check-auth'] },
			component: () => import('./Home.vue').then(m => m.default || m),
			children: [
				{
					path: ':messageId/:commentId?',
					name: 'post',
					meta: { middlewares: ['check-auth'] },
					component: () => import('./PostPage.vue').then(m => m.default || m),
				},
			],
		},
		{
			path: 'products',
			name: 'products',
			meta: { middlewares: ['check-auth'] },
			component: () => import('./Products.vue').then(m => m.default || m),
		},
		{
			path: 'resources',
			name: 'resources',
			meta: { middlewares: ['check-auth'] },
			component: () => import('./Resources.vue').then(m => m.default || m),
			children: [
				{
					path: ':resourceId',
					name: 'resource',
					meta: { middlewares: ['check-auth'] },
					component: () => import('./ResourceItem.vue').then(m => m.default || m),
				},
			],
		},
		{
			path: 'resources/dd/:resourceId',
			name: 'resourceDigitalDownload',
			meta: { middlewares: ['check-auth'] },
			component: () => import('./ResourceDDItem.vue').then(m => m.default || m),
		},
		{
			path: 'resources/folder/:resourceId',
			name: 'resourceFolder',
			meta: { middlewares: ['check-auth'] },
			component: () => import('./ResourceFolder.vue').then(m => m.default || m),
		},
		{
			path: 'resources/child/:resourceId',
			name: 'child',
			meta: { middlewares: ['check-auth'] },
			component: () => import('./ResourceItem.vue').then(m => m.default || m),
		},
		{
			path: 'members',
			name: 'members',
			meta: { middlewares: ['check-auth'] },
			component: () => import('./Members.vue').then(m => m.default || m),
		},
		{
			path: 'search',
			name: 'search',
			meta: { middlewares: ['check-auth'] },
			component: () => import('./Search.vue').then(m => m.default || m),
		},
	],
}

const communityMinimalRoutes = {
	path: '/:communitySlug',
	name: 'communityMinimal',
	redirect: '/:communitySlug',
	component: MinimalContainer,
	beforeEnter: PrepareStores,
	children: [
		{
			path: 'settings',
			name: 'settings',
			meta: { middlewares: ['check-auth'] },
			component: () => import('./Settings.vue').then(m => m.default || m),
		},
	],
}

const communitySetupRoutes = {
	path: '/:communitySlug',
	name: 'communitySetupRoutes',
	redirect: '/:communitySlug',
	component: EmptyContainer,
	beforeEnter: PrepareStores,
	children: [
		{
			path: 'setup',
			name: 'setup',
			meta: { middlewares: ['check-auth'] },
			component: () => import('./Setup.vue').then(m => m.default || m),
		},
	],
}

const communityNoAuthRoutes = {
	path: '/',
	name: 'communityNoAuth',
	component: NoAuthContainer,
	children: [
		{
			path: 'communities',
			name: 'communities',
			component: () => import('./AllCommunities.vue').then(m => m.default || m),
		},
		{
			path: 'forgot-password',
			name: 'genericForgotPassword',
			component: () => import('./ForgotPassword.vue').then(m => m.default || m),
		},
		{
			path: 'reset-password',
			name: 'genericResetPassword',
			component: () => import('./ResetPassword.vue').then(m => m.default || m),
		},
		{
			path: 'login',
			name: 'genericLogin',
			component: () => import('./Login.vue').then(m => m.default || m),
		},
		{ path: '/link-with-spotify', name: 'linkWithSpotify', component: () => import('./LinkWithSpotify.vue').then(m => m.default || m) },
		{ path: '/callback', name: 'spotifyCallback', component: () => import('./SpotifyCallback.vue').then(m => m.default || m) },
	],
}

export default [
	{
		path: '/',
		name: '404',
		component: () => import('../global/PageNotFound.vue').then(m => m.default || m),
	},
]
	.concat(communityNoAuthRoutes, communityLoginRoutes, communityRoutes, communityMinimalRoutes, communitySetupRoutes)
	.concat({
		path: '/:pathMatch(.*)*',
		redirect: '/',
	})
